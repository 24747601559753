import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import {Helmet} from "react-helmet"
import { useSpring, animated } from 'react-spring'

import fonts from "../styles/fonts"
import colors from "../styles/colors"
import device from '../styles/device'

import Layout from "../layout"
import ProjectsListItem from "../components/projectsListItem"
import ContactCta from "../components/contactCta"

//data:
import langData from '../data/languages/es'


const ProjectsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 8.3333%;

  @media ${device.tablet}{
    padding: 80px 30px;
  }

  @media ${device.phone}{
    padding: 64px 20px;
  }

  max-width: 1440px;
  margin: 0 auto;
`

const ProjectsBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;

  margin: 100px -10px 0;
  font-family: ${fonts.poppins};
  color: ${colors.tertiary};
`

const ProjectsTitle = styled.div`
  width: 100%;
  padding: 0 10px;

  h1 {
    font-size: 48px;
    font-weight: bold;
    letter-spacing: -2px;
    line-height: 54px;
    margin: 0;

    @media ${device.phone}{
      font-size: 40px;
      font-weight: bold;
      letter-spacing: -0.75px;
      line-height: 45px;
    }
  }

  p {
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    margin: 12px 0 40px;
  }
`

const Projects = ({data}) => {

  const titleProps = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    }
  })

  const AnimatedProjectsTitle = animated(ProjectsTitle);

  const {sideMenu, cookies, footer, projects: {helmet, title, description}, contactBanner: {normal} } = langData

  return (

    <Layout sideMenu={sideMenu} cookies={cookies} footer={footer}>

      <Helmet>
          <title>{helmet.title}</title>
          <meta name="description" content={helmet.meta} />
      </Helmet>
        
        <ProjectsContainer>

          <ProjectsBlock>

            <AnimatedProjectsTitle style={titleProps}>
          
              <h1>{title}</h1>
              <p>{description}</p>

            </AnimatedProjectsTitle>

          {/*
            Loop to create all the project items
          */}

          {data.allWordpressWpProject.edges.map(({node}) => (
              <ProjectsListItem
                key={'ProjectsItem' + Math.random()}
                title={node.acf.project_header.project_header_title}
                description={node.acf.project_header.project_header_subtitle}
                image={node.acf.project_header.project_header_image.localFile.childImageSharp.fluid}
                url={langData.sideMenu.primary[0].slug + node.slug}
                color_top={node.acf.project_header.project_header_gradient_colors.project_header_gradient_colors_top}
                color_bottom={node.acf.project_header.project_header_gradient_colors.project_header_gradient_colors_bottom}
              />
          ))}

          </ProjectsBlock>

        </ProjectsContainer>

        <ContactCta normal={normal}/>

      </Layout>

  )
}

export default Projects

export const pageQuery = graphql`
query {
  allWordpressWpProject(filter: {wordpress_site: {eq: "es"}}, sort: { fields: [date], order: DESC }) {
    edges {
      node {
        slug
        path
        acf {
          project_header {
            project_header_title
            project_header_subtitle
            project_header_gradient_colors {
              project_header_gradient_colors_top
              project_header_gradient_colors_bottom
            }
            project_header_image {
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 1280, quality: 72) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`